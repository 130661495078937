@import "antd/dist/reset.css";
@import "markdown.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-ja leading-relaxed tracking-wider;
  }
  input:focus {
    box-shadow: none !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  html {
    scrollbar-width: none;
  }
}

@layer utilities {
  .bg-gradient-diagonal-lines {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 2px,
      rgba(229, 231, 235, 0.5) 2px,
      rgba(229, 231, 235, 0.5) 4px
    );
    background-size: 40px 40px;
  }
  .custom-select .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }
  .custom-select .ant-select-selector:hover,
  .custom-select .ant-select-selector:focus {
    border-right-width: 0.5px !important;
  }

  .custom-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0.5px !important;
  }

  .custom-button-revert {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }

  .custom-button-revert:not(:disabled):hover {
    border-right-width: 0.5px !important;
    border-right-color: #ff6640 !important;
  }

  .dropdown-button {
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
  }

  .dropdown-button:hover {
    border-left-width: 1px;
  }

  .custom-tabs .ant-tabs-tab {
    font-size: 14px;
    border-bottom-color: #e5e7eb;
  }

  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }

  .custom-row-class td {
    background-color: #f3f4f6;
  }

  .cell-boder:first-child {
    border-left: 1px solid #e5e7eb;
  }

  .custom-menu .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .custom-menu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  /* サブメニュー内のメニューアイテムに適用 */
  .custom-menu .ant-menu-submenu .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .custom-date-picker .ant-picker-input > input {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }

  .custom-date-picker .ant-picker {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }

  .time-indicator {
    position: fixed;
    width: 1.5px;
    height: 100%;
    background-color: #ef4444;
    z-index: 200;
  }

  .time-indicator::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #ef4444;
    transform: translateX(-50%);
  }

  .react-resizable {
    padding: 0;
  }

  .calendar-cell {
    border: 0.5px solid #e5e7eb;
    padding: 10px;
    cursor: pointer;
  }

  .calendar-cell.selected {
    background-color: #a7f3d0;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .printPages,
  .printPages * {
    visibility: visible;
  }
  .printPages {
    position: absolute;
    left: 0;
    top: 0;
    width: 297mm;
    height: 210mm;
    margin: 0%;
    padding: 0;
  }
}
